import React from "react";
import CaseStudies from "../../components/CaseStudies";
import Layout from "../../components/Layout";
import MyHelmet from "../../context/MyHelmet";

export default () => {
  return (
    <div>
      <MyHelmet page="case-studies" language="fr" />
      <Layout>
        <CaseStudies />
      </Layout>
    </div>
  );
};
